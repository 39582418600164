<template>
  <div class="px-4">
    <v-form v-if="!id" class="mt-3">
      <v-card class="pa-2 mx-auto">
        <h2 class="secondary pa-3">MPs INFORMATION</h2>
        <v-row class="mt-3">
          <v-col cols="12" sm="6">
            <b>CDP Ref No:</b>
            <v-select
              :items="cdps_mps"
              @change="getCurrentRefData()"
              v-model="allmpz"
              item-text="CDPRef"
              item-value="CDPRef"
              return-object
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <h3 class="text-center"><b>Type of Project:</b> <br> {{ allmpz.projects ? allmpz.projects :'No project selected' }} </h3>  
          </v-col>
        </v-row>
        <div v-for="(mps, index) in dform.mps_info" :key="mps.id">
          <h3 class="success--text">MPs APPROVED:</h3>
          <div class="mt-3">
            <b>MP Ref No:</b>
            {{index}}
            <v-text-field
              v-model.trim="mps.MPsRefNo"
              outlined
              dense
              readonly
            ></v-text-field>
          </div>
          <v-row>
            <v-col cols="12 " sm="6">
              <b>Approved MPs/GMPs:</b>
              <v-text-field
                v-model.trim="mps.MPsApproved"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>Total cost of MPs/GMPs:</b>
              <v-text-field
                v-model.number="mps.MPsTotalCost"
                @change="processContributnPercent(mps)"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>Agency Contribution:</b>
              <v-text-field
                v-model.number="mps.AgencyContribution"
                type="number"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>Community Contribution:</b>
              <v-text-field
                v-model.number="mps.CommunityContribution"
                type="number"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>            

            <v-col cols="12" sm="6">
              <b>Sector:</b>
              <v-select
                v-model.trim="mps.Sector"
                :items="sectors"
                outlined
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <b>Cordinates (Longitude):</b>
              <v-text-field
                v-model.trim="mps.CordinatesLong"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <b>Cordinates (Latitude):</b>
              <v-text-field
                v-model.trim="mps.CordinatesLat"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>First Tranche:</b>
              <v-text-field
                v-model.number="mps.FirstTranche"
                type="number"
                outlined
                dense
                @change="processFundNotYetRelease(mps)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>Date Release First Tranche:</b>
              <v-text-field
                v-model.trim="mps.FirstTrancheDate"
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>Second Tranche:</b>
              <v-text-field
                v-model.number="mps.SecondTranche"
                type="number"
                outlined
                dense
                @change="processFundNotYetRelease(mps)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>Date Release Second Tranche:</b>
              <v-text-field
                v-model.trim="mps.SecondTrancheDate"
                type="date"
                outlined
                dense              
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>Third Tranche:</b>
              <v-text-field
                v-model.number="mps.ThirdTranche"
                type="number"
                outlined
                dense
                @change="processFundNotYetRelease(mps)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <b>Date Release Third Tranche:</b>
              <v-text-field
                v-model.trim="mps.ThirdTrancheDate"
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <b>Fund Not Yet Release:</b>
              <v-text-field
                v-model="mps.FundNotYetRelease"
                type="number"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <b>Commence Date:</b>
              <v-text-field
                v-model.trim="mps.CommenceDate"
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <b>Status:</b>
              <v-select
                v-model.trim="mps.Status"
                :items="status"
                outlined
                dense
              ></v-select>
            </v-col>

            <v-col cols="12">
              <b>Comment:</b>
              <v-textarea
                v-model.trim="mps.mpsComment"
                rows="2"
                auto-grow
                outlined
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12">
            <b>Image Of Project:</b>
           <v-file-input
            placeholder="Upload file in excel format"
            accept=".jpeg, .jpg"
            prepend-icon="mdi-file"
            v-if="!mps.Projectimg || edit.Projectimg"
             @change="handleprojectimg"
             @click="getimg(mps)"
            outlined
          ></v-file-input>

          <div class="text-center" v-else>
            <b>Image Of Project</b>
            <v-icon size="4rem">mdi-file</v-icon>
            <v-btn
              color="primary"
              small
              shaped
              @click="edit.Projectimg = true"
              class="mr-1"
              >change file</v-btn
            >
          </div>

          <div class="text-center" v-if="edit.Projectimg">
            <v-btn
              color="primary"
              small
              shaped
              @click="edit.Projectimg = false"
              >Image Of Project</v-btn
            >
          </div>
          </v-col>
          </v-row>
          <br />
        </div>

        <p class="text-end">
          <v-btn @click="post" :loading="loading" color="success"
            >Save Changes</v-btn
          >
        </p>
      </v-card>
    </v-form>

    <v-form v-else class="mt-3">
      <v-card class="pa-2 mx-auto">
        <h2 class="secondary pa-3">MPs INFORMATION</h2>
        <div class="mt-3">
          <b>MP Ref No:</b>
          <v-text-field
            v-model.trim="dform.MPsRefNo"
            outlined
            dense
            readonly
          ></v-text-field>
        </div>
        <v-row>
          <v-col cols="12 " sm="6">
            <b>Approved MPs/GMPs:</b>
            <v-text-field
              v-model.trim="dform.MPsApproved"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Agency Contribution:</b>
            <v-text-field
              v-model.number="dform.AgencyContribution"
              type="number"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Community Contribution:</b>
            <v-text-field
              v-model.number="dform.CommunityContribution"
              type="number"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Total cost of MPs/GMPs:</b>
            <v-text-field
              v-model.number="dform.MPsTotalCost"
              type="number"
              @change="calculate1"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <b>Sector:</b>
            <v-select
              v-model.trim="dform.Sector"
              :items="sectors"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6">
            <b>Cordinates (Longitude):</b>
            <v-text-field
              v-model.trim="dform.CordinatesLong"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <b>Cordinates (Latitude):</b>
            <v-text-field
              v-model.trim="dform.CordinatesLat"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>First Tranche:</b>
            <v-text-field
              v-model.number="dform.FirstTranche"
              type="number"
              @change="calculate1"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Date Release First Tranche:</b>
            <v-text-field
              v-model.trim="dform.FirstTrancheDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Second Tranche:</b>
            <v-text-field
              v-model.number="dform.SecondTranche"
              type="number"
              @change="calculate1"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Date Release Second Tranche:</b>
            <v-text-field
              v-model.trim="dform.SecondTrancheDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Third Tranche:</b>
            <v-text-field
              v-model.number="dform.ThirdTranche"
              type="number"
              @change="calculate1"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Date Release Third Tranche:</b>
            <v-text-field
              v-model.trim="dform.ThirdTrancheDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <b>Fund Not Yet Release:</b>
            <v-text-field
              v-model="dform.FundNotYetRelease"
              type="number"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <b>Commence Date:</b>
            <v-text-field
              v-model.trim="dform.CommenceDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <b>Status:</b>
            <v-select
              v-model.trim="dform.Status"
              :items="status"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12">
            <b>Comment:</b>
            <v-textarea
              v-model.trim="dform.mpsComment"
              rows="2"
              auto-grow
              outlined
              dense
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <b>Image Of Project:</b>
           <v-file-input
            placeholder="Upload file in excel format"
            accept=".jpeg, .jpg, .png"
            prepend-icon="mdi-file"
            v-if="!dform.Projectimg || edit.Projectimg"
             @change="handleprojectimg"
            outlined
          ></v-file-input>

          <div class="text-center" v-else>
            <b>Image Of Project</b>
            <v-icon size="4rem">mdi-file</v-icon>
            <v-btn
              color="primary"
              small
              shaped
              @click="edit.Projectimg = true"
              class="mr-1"
              >change file</v-btn
            >
          </div>

          <div class="text-center" v-if="edit.Projectimg">
            <v-btn
              color="primary"
              small
              shaped
              @click="edit.Projectimg = false"
              >Image Of Project</v-btn
            >
          </div>
          </v-col>
        </v-row>

        <p class="text-end">
          <v-btn :loading="loading" @click="saveChanges" color="success"
            >Save Changes</v-btn
          >
        </p>
      </v-card>
    </v-form>


    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, editUserMixin } from "@/mixins";
import { mapState, mapGetters } from "vuex";
export default {
  name: "MpsInformation",
  mixins: [snackMixin, editUserMixin],

  data() {
    return {
      curMps: {},
      cdps_mps: [],
      dform: {},
      allmpz: [],
      id: false,
      edit: {
        Projectimg: false,
      }
    };
  },

  computed: {
    ...mapState(["sectors", "status", "cdps"]),
    ...mapGetters(["getCurUser","agencyContributn","communityContributn"]),
  },

  async created() {
    await apiClient.get("/cdps_info?u=3").then((res) => {
      if (Array.isArray(res.data)) {
        this.cdps_mps = res.data;
      }
    });
    // .catch((err) => this.displayMsg(err.message, "error"));

    if (this.editUser) {
      this.id = true;
    }
  },

  methods: {
    processContributnPercent(mps){
   
      const params = {projectType:this.allmpz.projects, projectCost:mps.MPsTotalCost}
      mps.AgencyContribution = this.agencyContributn(params)
      mps.CommunityContribution = this.communityContributn(params)
      this.processFundNotYetRelease(mps)
    },
    processFundNotYetRelease(mps){
     
      mps.FundNotYetRelease = mps.MPsTotalCost - (mps.FirstTranche + mps.SecondTranche + mps.ThirdTranche)
    },
    calculate1() {
      this.dform.FundNotYetRelease =
        this.dform.mps_info.MPsTotalCost -
        (this.dform.mps_info.FirstTranche +
          this.dform.mps_info.SecondTranche +
          this.dform.mps_info.ThirdTranche);
    },
    async getCurrentRefData() {
      this.dform.mps_info = this.allmpz.mps_info;
    },


    post() {
      if (!this.dform.mps_info) {
        this.displayMsg("Please enter all necessary fields", "error");
        return;
      }
      this.loading = true;
      this.dform.type = "mps";
      apiClient
        .put("/mps_info", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.allmpz = [];
            this.dform = {};
            this.loading = false;
            this.displayMsg("Mps updated successfully");
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
    });
  },
  getimg(mps){
    this.curMps = mps;
  },
  handleprojectimg(file) {
      this.processFile(
        file,
        "Projectimg",
      );
    },


    processFile(file, filename) {
      if (!file) {
        return;
      }
      const validFile =
        file.name.includes(".png") || file.name.includes(".jpeg") || file.name.includes(".jpg");
      if (!validFile) {
        this.displayMsg(
          "Invalid file uploaded! Please upload excel file",
          "error"
        );
        return;
      }
      let fd = new FormData();
      let data = {
        id: this.curMps.id,
        filename: this.curMps[filename],
      };

      fd.append("file", file);
      fd.append("data", JSON.stringify(data));
      let config = { header: { "Content-Type": "multipart/form-data" } };

      apiClient
        .post("/process_projectimg", fd, config)
        .then((res) => {
          if (res.data.filename) {
            
            this.dform[filename] = res.data.filename;
       

            this.edit.Projectimg = false;
            this.displayMsg("Excel file uploaded succesfully");
          } else {
            this.displayMsg(res.data, "error");
            this.loading = false;
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    },

    

    saveChanges() {
      this.dform.type = "single_mps";
      this.loading = true;
      this.dform.modified_by = this.getCurUser;
      apiClient
        .put("/mps_info", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.displayMsg("Changes saved successfully.Redirecting ...");
            this.loading = false;
            setTimeout(() => {
              this.$router.push({
                name: "MpsInformationTable",
              });
            }, 2000);
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    },
  },
};
</script>
